@mixin magic-border($width, $color, $duration, $direction){
    position:relative;
        &:before{
            content:'';
            position:absolute;
            width:calc(100% + #{$width * 2});
            height:calc(100% + #{$width * 2});
            top:calc(#{$width}/-1);
            left:calc(#{$width}/-1);
            background:linear-gradient(to right, $color 0%, $color 100%), linear-gradient(to top, $color 50%, transparent 50%), linear-gradient(to top, $color 50%, transparent 50%), linear-gradient(to right, $color 0%, $color 100%), linear-gradient(to left, $color 0%, $color 100%);
            background-size:100% $width, $width 200%, $width 200%, 0% $width, 0% $width;
            background-position:50% 100%, 0% 0%, 100% 0%, 100% 0%, 0% 0%;
            background-repeat:no-repeat, no-repeat;
            transition:transform $duration ease-in-out, background-position $duration ease-in-out, background-size $duration ease-in-out;
            transform:scaleX(0) rotate(180deg * $direction);
            transition-delay:$duration*2, $duration, 0s;
        }
        &:hover{
            &:before{
                background-size:200% $width, $width 400%, $width 400%, 55% $width, 55% $width;
                background-position:50% 100%, 0% 100%, 100% 100%, 100% 0%, 0% 0%;
                transform:scaleX(1) rotate(180deg * $direction);
                transition-delay:0s, $duration, $duration*2;
            }
        }
}

.sick-border {
    background-color: transparent;
    border: none;
    margin-bottom: 16px;
    @include magic-border(2px, #d34f38, 0.1s, 1);
    cursor:pointer;
}

.project-tile {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-bottom: 32px;
}

.hide {
    white-space: nowrap;
    font-size: 24px;
    position: absolute;
    bottom: 0;
    display: none;
}

.sick-border:hover + .hide {
    display: block;
}